import air1 from '../carousell/8.jpg'
import air2 from '../carousell/9.jpg'
import air3 from '../carousell/10.jpg'
import air4 from '../carousell/11.jpg'
import ald1 from '../carousell/12.jpg'
import cer1 from '../carousell/13.jpg'
import cer2 from '../carousell/14.jpg'
import eco1 from '../carousell/15.jpg'
import eco2 from '../carousell/16.jpg'
import ent1 from '../carousell/2.jpg'
import ent2 from '../carousell/3.jpg'
import ent3 from '../carousell/4.jpg'
import ent4 from '../carousell/5.jpg'
import fin1 from '../carousell/18.jpg'
import fin2 from '../carousell/19.jpg'
import ser1 from '../carousell/21.jpg'
import ser2 from '../carousell/22.jpg'
import ser3 from '../carousell/23.jpg'
import serv1 from '../carousell/24.jpg'
import serv2 from '../carousell/25.jpg'
import serv3 from '../carousell/26.jpg'
import serv4 from '../carousell/27.jpg'
import serv5 from '../carousell/28.jpg'
import yap1 from '../carousell/29.jpg'
import yap2 from '../carousell/30.jpg'
import yap3 from '../carousell/31.jpg'
import vid from './entel empresas/ENTEL FERIA FOOD SERVICE DIA 1.mp4'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "vid1": vid,
    "img1": ent1,
    "img2": ent2,
    "img3": ent3,
    "img4": ent4,
    "img5": serv1,
    "img6": serv2,
    "img7": serv3,
    "img8": serv4,
    "img9": serv5,
    "img11": air1,
    "img12": air2,
    "img13": air3,
    "img14": air4,
    "img15": ald1,
    "img16": cer1,
    "img17": cer2,
    "img18": eco1,
    "img19": eco2,
    "img20": fin1,
    "img21": fin2,
    "img22": ser1,
    "img23": ser2,
    "img24": ser3,
    "img25": yap1,
    "img26": yap2,
    "img27": yap3,
}