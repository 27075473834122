import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


function Clientes() {
  return (
    <Container id="clientes">
      <h2 className='titulo-seccion'>
        <span className='margin-text'>Cli</span>entes
        <span className='calypso'>.</span>
      </h2>
    </Container>
  );
}

export default Clientes;

